import React, {useEffect, useState} from "react";
import {database} from "../../../../firebase";
import {countryListAllIsoData} from "../../../../components/commons/CountryData";
import {useSelector, useDispatch} from "react-redux";
import {selectAccounts} from "../../../../redux/account/account.selectors";

import {Form, Modal, FloatingLabel, Row, Col} from "react-bootstrap";
import ReelerButton from "../../../../components/commons/ReelerButton";
import {useForm, Controller} from "react-hook-form";
import {setCreator} from "../../../../redux/creators/creators.actions";
import useUserFeedbackMessage from "../../../../hooks/useUserFeedbackMessage";
import {updateAsset} from "../../../../redux/asset/asset.actions";
import Text from "../../../../components/commons/Text";
import SelectCreatorList from "../../../../components/creators/SelectCreatorList";
import {GENDER_OPTIONS} from "../../../../constants";

export default function EditCreator({title, btn, creator, assetId}) {
  const {setErrorMessage, setSuccessMessage} = useUserFeedbackMessage();
  const [isSaving, setIsSaving] = useState(false);
  const [lists, setLists] = useState([]);
  const [show, setShow] = useState(false);
  const account = useSelector(selectAccounts);
  const dispatch = useDispatch();

  // need to set default values, notes is also undefiende with contoller so need to explicit set it it to empty string
  const defaultValues = creator
    ? {...creator, notes: creator?.notes ? creator?.notes : ""}
    : {notes: ""};

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    setError,
    clearErrors,
    control,
  } = useForm({defaultValues});

  const handleClose = () => {
    setShow(false);
    clearErrors();
  };
  const handleShow = () => setShow(true);

  const ValidationMessage = ({message}) => (
    <small style={{color: "var(--reeler-danger"}}>{message}</small>
  );

  const Input = ({
    label,
    type = "text",
    fieldName,
    register,
    validation,
    validationErrors,
    filedDescription,
  }) => (
    <Form.Group className="mb-3">
      <FloatingLabel controlId={fieldName} label={label}>
        <Form.Control
          type={type}
          placeholder={label}
          size="sm"
          {...register(fieldName, validation)}
          aria-invalid={errors.firstName ? "true" : "false"}
        />
      </FloatingLabel>
      {validationErrors && validationErrors[fieldName] && (
        <ValidationMessage message={validationErrors[fieldName].message} />
      )}
      {filedDescription && (
        <Form.Text className="text-muted">{filedDescription}</Form.Text>
      )}
    </Form.Group>
  );

  const TextArea = ({
    label,
    height = "100px",
    fieldName,
    validation,
    validationErrors,
  }) => (
    <Form.Group className="mb-3">
      <FloatingLabel controlId={fieldName} label={label}>
        <Controller
          name={fieldName}
          control={control}
          rules={validation}
          render={({field: {onChange, value}}) => (
            <Form.Control
              as="textarea"
              style={{height}}
              placeholder={label}
              size="sm"
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FloatingLabel>
      {validationErrors && validationErrors[fieldName] && (
        <ValidationMessage message={validationErrors[fieldName].message} />
      )}
    </Form.Group>
  );
  const Select = ({
    label,
    fieldName,
    placeholder,
    register,
    validation,
    validationErrors,
    options,
  }) => (
    <Form.Group className="mb-3">
      <FloatingLabel controlId={fieldName} label={label}>
        <Form.Control
          as="select"
          size="sm"
          {...register(fieldName, validation)}
          aria-invalid={errors.firstName ? "true" : "false"}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Form.Control>
      </FloatingLabel>
      {validationErrors && validationErrors[fieldName] && (
        <ValidationMessage message={validationErrors[fieldName].message} />
      )}
    </Form.Group>
  );

  const onSubmit = async (data) => {
    setIsSaving(true);
    if (lists.length > 0) {
      let creatorLists = {};

      lists.forEach((l) => {
        if (l === "") {
          return;
        }
        creatorLists[l.toLowerCase()] = true;
      });
      data["lists"] = creatorLists;
    } else {
      data["lists"] = {};
    }

    if (creator?.id) {
      // Check if the creator already exists
      if (data?.email && data?.email !== creator.email) {
        const useSnap = await database.creators
          .where("accounts", "array-contains", account.id)
          .where("email", "==", data.email)
          .get();

        if (!useSnap.empty) {
          setErrorMessage("Email already exists, please use another email");
          setError("email", {
            type: "custom",
            message: "Email already exists, please use another email",
          });
          return;
        } else {
          clearErrors("email");
        }
      } else {
        clearErrors("email");
      }

      database.creators
        .doc(creator.id)
        .update({
          ...data,
        })
        .then(() => {
          // if username is update, update the creator username in the asset
          if (data.ig_username) {
            database.assets
              .where("accountId", "==", account.id)
              .where("creatorId", "==", creator.id)
              .get()
              .then((querySnapshot) => {
                const assets = [];
                querySnapshot.forEach((doc) => {
                  assets.push(doc.id);
                });

                const promises = assets.map((assetId) => {
                  return database.assets.doc(assetId).update({
                    username: data.ig_username,
                  });
                });
                return Promise.all(promises).catch((err) => {
                  console.log(err);
                });
              });
          }
        })
        .then(() => {
          dispatch(setCreator({...creator, ...data}));
          setSuccessMessage("Creator updated successfully");
          setIsSaving(false);
        });
    } else {
      // Check if the creator already exists
      if (data?.email) {
        const useSnap = await database.creators
          .where("accounts", "array-contains", account.id)
          .where("email", "==", data.email)
          .get();

        if (!useSnap.empty) {
          setErrorMessage("Email already exists, please use another email");
          setError("email", {
            type: "custom",
            message: "Email already exists, please use another email",
          });

          return;
        } else {
          clearErrors("email");
        }
      } else {
        clearErrors("email");
      }

      database.creators
        .add({
          ...data,
          accounts: [account.id],
          createdAt: database.getCurrentTimestamp(),
        })
        .then(async (docRef) => {
          if (assetId) {
            await database.assets.doc(assetId).update({
              creatorId: docRef.id,
            });
            dispatch(updateAsset({creatorId: docRef.id}));
            setSuccessMessage(
              "Added and connected creator successfully to asset"
            );
          } else {
            setSuccessMessage("Creator created successfully");
          }
          isSaving(false);
          reset();
          setLists([]);
          handleClose();
        });
    }
  };

  useEffect(() => {
    if (creator?.lists) {
      setLists(Object.keys(creator?.lists));
    }
  }, [creator]);

  return (
    <>
      <span onClick={handleShow}>{btn}</span>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Text size="md" weight="bold" color="var(--reeler-title)">
                  Info
                </Text>
                <Row>
                  <Col>
                    <Input
                      label="First name"
                      fieldName="firstName"
                      register={register}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Last name"
                      fieldName="lastName"
                      register={register}
                    />
                  </Col>
                </Row>

                <Input
                  label="Email"
                  fieldName="email"
                  register={register}
                  validation={{
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Wrong email format",
                    },
                  }}
                  validationErrors={errors}
                />
                <Input
                  label="Phone"
                  fieldName="phone"
                  type="tel"
                  register={register}
                  filedDescription={
                    "Include country code, no spaces or punctuation: +1234567890"
                  }
                />
                <Input
                  label="Instagram username"
                  fieldName="ig_username"
                  register={register}
                />
                <Select
                  label="Gender"
                  fieldName="gender"
                  placeholder={"Select a gender"}
                  register={register}
                  options={GENDER_OPTIONS}
                />
                <Input
                  label="Birthday"
                  type="date"
                  fieldName="birthday"
                  register={register}
                />
                <Form.Group>
                  <Form.Label>Lists</Form.Label>
                  <SelectCreatorList value={lists} handleOnChange={setLists} />
                </Form.Group>
              </Col>
              <Col>
                <Text size="md" weight="bold" color="var(--reeler-title)">
                  Address
                </Text>
                <Input
                  label="Address"
                  fieldName="address.street1"
                  register={register}
                />
                <Input
                  label="Address line 2"
                  fieldName="address.street2"
                  register={register}
                />

                <Row>
                  <Col>
                    <Input
                      label="City"
                      fieldName="address.city"
                      register={register}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="State"
                      fieldName="address.state"
                      register={register}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Postal code"
                      fieldName="address.postalCode"
                      register={register}
                    />
                  </Col>
                </Row>
                <Select
                  label="Country"
                  fieldName="address.country"
                  register={register}
                  placeholder={"Select a country"}
                  options={countryListAllIsoData.map((c) => ({
                    label: c.name,
                    value: c.name,
                  }))}
                />
                <div className="mt-4">
                  <Text size="md" weight="bold" color="var(--reeler-title)">
                    Notes
                  </Text>
                  <TextArea
                    label="Notes"
                    fieldName="notes"
                    height={"150px"}
                    placeholder={"Add a creator note"}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ReelerButton
              styleClass="btn-secondary mx-2"
              dispatch={handleClose}
              text="Close"
            />

            <ReelerButton
              type="submit"
              styleClass="btn-main"
              text="Save Changes"
              disabled={isSaving}
              loading={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
