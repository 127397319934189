import React, {useState} from "react";

// Firebase
import {database} from "../../../../../../firebase";

// Redux
import {useSelector, useDispatch} from "react-redux";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";
import {selectCurrentUser} from "../../../../../../redux/user/user.selectors";
import {updateAsset} from "../../../../../../redux/asset/asset.actions";

import Rating from "@material-ui/lab/Rating";
import {FaBan} from "react-icons/fa";

// Reeler components
import {ModalTabHeading} from "../../styles";
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent";
import {ACCOUNT_PLANS, USER_ROLES} from "../../../../../../constants/routes";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";

export default function AssetRate() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const asset = useSelector(selectActiveAsset);

  const handleUpdateRating = (newValue) => {
    setLoading(true);

    // if newValue !== null, else remove user raiting if it exisits
    if (newValue !== null) {
      let sumOfRatings = newValue;
      let roundedValue = newValue;

      if (asset.ratings) {
        Object.values(asset.ratings).forEach((rating, index) => {
          sumOfRatings = sumOfRatings + rating.rating;
        });

        roundedValue = sumOfRatings / (Object.values(asset.ratings).length + 1);
      }

      let updateData = {
        rating: roundedValue,
        [`ratings.${currentUser.id}`]: {
          rating: newValue,
          name:
            currentUser.firstName || currentUser.lastName
              ? currentUser.firstName + " " + currentUser.lastName
              : currentUser.email,
        },
      };

      database.assets
        .doc(asset.id)
        .update(updateData)
        .then(() => {
          dispatch(
            updateAsset({
              ...asset,
              rating: roundedValue,
              ratings: {
                ...asset.ratings,
                [currentUser.id]: {
                  rating: newValue,
                  name:
                    currentUser.firstName || currentUser.lastName
                      ? currentUser.firstName + " " + currentUser.lastName
                      : currentUser.email,
                },
              },
            })
          );
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      if (currentUser.id in asset.ratings) {
        // remove existing rating
        let ratings = asset.ratings;
        delete ratings[currentUser.id];

        let sumOfRatings = 0;
        let roundedValue = 0;

        if (ratings) {
          Object.values(ratings).forEach((rating) => {
            sumOfRatings = sumOfRatings + rating.rating;
          });

          roundedValue = sumOfRatings / Object.values(ratings).length;
        }

        const updateData = {
          rating: roundedValue,
          ratings,
        };

        database.assets
          .doc(asset.id)
          .update(updateData)
          .then(() => {
            dispatch(
              updateAsset({
                ...asset,
                ...updateData,
              })
            );
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <AuthBasedComponent
      plans={[ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium]}
    >
      <div className="d-flex flex-row align-items-center">
        <FaBan
          size={14}
          className="me-1 mb-1 icon-btn icon-color"
          onClick={() => {
            handleUpdateRating(null);
          }}
        />
        <ReelerTooltip
          text={
            <div className="d-flex flex-column ">
              <small>Rated by:</small>
              {asset.ratings &&
                Object.values(asset.ratings).map((rating, index) => (
                  <span
                    key={index}
                    className="ms-1 "
                    style={{fontSize: "0.7rem"}}
                  >
                    {rating.name + " : " + rating.rating}
                  </span>
                ))}
            </div>
          }
        >
          <Rating
            size="small"
            name="rate-asset"
            className="icon-star-active"
            value={asset.rating}
            onChange={(event, newValue) => {
              handleUpdateRating(newValue);
            }}
            onChangeActive={(event, newHover) => {
              // setHover(newHover)
            }}
            disabled={currentUser.role === USER_ROLES.viewer}
          />
        </ReelerTooltip>
      </div>
    </AuthBasedComponent>
  );
}
